import React from 'react'
import {logout} from '../../common/api'
import {Icons, HBox} from '@bakkt/components'
import PermissionManager, {PortalPermission} from '../../app/PermissionManager'
import PartnerSettings from './PartnerSettings'
import SwitchPartner from './SwitchPartner'
import {useSelector} from 'react-redux'
import {selectCurrentUser} from '../../app/reducer'

export default () => {
	const currentUser = useSelector(selectCurrentUser)
	return (
		<div style={{marginLeft: 115, marginRight: 100, marginTop: 25, marginBottom: 25}}>
			<HBox style={{alignItems: 'center'}}>
				<span style={{fontSize: 20, fontFamily: 'BrownPro', display: 'flex'}}>
					Bakkt<span style={{fontSize: 17}}>&#174;&nbsp;</span> Partner Portal
				</span>
				<div style={{display: 'flex', flexGrow: 1}}></div>
				<Icons.BakktLogo width={148} height={57} />
				<div style={{display: 'flex', flexGrow: 1}}></div>
				{/* TODO check permissions */}
				{PermissionManager.userAllowed([PortalPermission.USERS_VIEW]) && <PartnerSettings />}
				{currentUser.partnerIds.length > 1 && <SwitchPartner />}
				<span style={{fontSize: 16, cursor: 'pointer', fontFamily: 'BrownPro'}} onClick={logout}>
					Sign Out
				</span>
			</HBox>
		</div>
	)
}
