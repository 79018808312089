import React from 'react'
import {Icons, Menu, MenuItem} from '@bakkt/components'
import application from '../../app/Application'
import {WIDGET_ID} from '../../manifest'
import PermissionManager, {PortalPermission, PartnerRolePermissionEnum} from '../../app/PermissionManager'

interface IProps {}

interface IState {
	anchorEl: Element | null
	clientY: number
	clientX: number
}

export default class PartnerSettings extends React.Component<IProps, IState> {
	constructor(props: any) {
		super(props)
		this.state = {
			anchorEl: null,
			clientY: 0,
			clientX: 0,
		}
	}

	handleClick = (event: any) => {
		this.setState({anchorEl: event.currentTarget, clientX: event.clientX, clientY: event.clientY})
	}

	handleClose = () => {
		this.setState({anchorEl: null, clientX: 0, clientY: 0})
	}

	showUsersTab = () => {
		this.handleClose()
		application.openTab(
			`User Management`,
			WIDGET_ID.PARTNER_USERS,
			{
				id: WIDGET_ID.PARTNER_USERS,
				viewInTab: true,
			},
			WIDGET_ID.PARTNER_USERS,
		)
	}

	showUserProfile = () => {
		this.handleClose()
		application.openTab(
			`Partner Profile`,
			WIDGET_ID.PARTNER_MERCHANT_PROFILE,
			{
				id: WIDGET_ID.PARTNER_MERCHANT_PROFILE,
				viewInTab: true,
			},
			WIDGET_ID.PARTNER_MERCHANT_PROFILE,
		)
	}

	showRoleManagementTab = () => {
		this.handleClose()
		application.openTab(
			`Role Management`,
			WIDGET_ID.ROLE_MANAGEMENT,
			{
				id: WIDGET_ID.ROLE_MANAGEMENT,
				viewInTab: true,
			},
			WIDGET_ID.ROLE_MANAGEMENT,
		)
	}

	render() {
		const {anchorEl, clientY, clientX} = this.state
		return (
			<>
				<span
					style={{fontSize: 16, cursor: 'pointer', fontFamily: 'BrownPro', marginRight: 35, display: 'flex'}}
					onClick={e => this.handleClick(e)}>
					Settings <Icons.ChevronDownSmll />
				</span>
				<Menu
					anchorPosition={{top: clientY + 15, left: clientX - 60}}
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={this.handleClose}>
					{PermissionManager.userAllowed([PartnerRolePermissionEnum.USERS_VIEW]) && (
						<MenuItem onClick={this.showUsersTab}>User Management</MenuItem>
					)}
					{PermissionManager.userAllowed([PartnerRolePermissionEnum.ROLE_CREATE_UPDATE]) && (
						<MenuItem onClick={this.showRoleManagementTab}>Role Management</MenuItem>
					)}
				</Menu>
			</>
		)
	}
}
