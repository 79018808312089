import {usersApi, logout, openURLTab, partnerUserApi, getPortalType, getPortalTypeApiReq} from '../common'
import {actions} from './reducer'
import {User, URLParam} from './AppState'
import {store} from '../store'
import {WIDGET_ID} from '../manifest'
import PermissionManager from './PermissionManager'
import {Application as BakktComponentApplication} from '@bakkt/components'
import {showPartnerSelectDialog} from '../common/components/PartnerSelectDialog'
class Application {
	respenseReceived() {
		const dispatch = store.dispatch
		dispatch(actions.responseReceived())
	}
	requestSent() {
		const dispatch = store.dispatch
		dispatch(actions.requestSent())
	}

	getURLInfo() {
		const searchedURL = new URLSearchParams(window.location.search)
		if (
			localStorage.getItem('portal-type') === 'partner' &&
			localStorage.getItem('selectedPartnerId') &&
			!searchedURL.has('selectedPartnerId')
		) {
			window.history.replaceState(null, null, '?selectedPartnerId=' + localStorage.getItem('selectedPartnerId'))
		}
		if (searchedURL.has('selectedPartnerId')) {
			localStorage.setItem('selectedPartnerId', searchedURL.get('selectedPartnerId'))
		}

		if (searchedURL.has('transaction-id')) {
			let urlSetting = JSON.stringify({
				paramName: 'transaction-id',
				paramId: searchedURL.get('transaction-id'),
			} as URLParam)
			localStorage.setItem('url-parameter', urlSetting)
		}
	}

	async fetchUserInfo() {
		let info
		try {
			info = await partnerUserApi.getPartnerUserSessionInfo()
		} catch (error) {
			const info = await getPortalTypeApiReq()
			if (info?.data?.partnerIds) {
				const errorMessage = error?.response?.data?.error?.message || 'Something went wrong'
				showPartnerSelectDialog(errorMessage, info.data.partnerIds)
			}
			return
		}

		const {roles, email, permissions, name, supportedOperations, isBakktUser, subscribedApis} = info?.data
		const partners = info.data?.partners || {}

		const partnerIds = (info.data?.partners && Object.keys(info.data.partners)) || []

		return {
			email,
			name,
			partnerIds,
			partners,
			isBakktUser,
			roles,
			permissions,
			supportedOperations,
			subscribedApis,
		} as User
	}

	async loadOnStartup() {
		await getPortalType()
		this.getURLInfo()
		const dispatch = store.dispatch
		const currentUser = await this.fetchUserInfo()
		if (currentUser) {
			PermissionManager.setCurrentUser(currentUser)
			// handle no portal permissions case
			if (!PermissionManager.isAnyCSRAdminPermission()) {
				logout()
			} else {
				dispatch(actions.staticDataLoad(currentUser))
				const partnerId =
					localStorage.getItem('selectedPartnerId') ||
					(currentUser?.partnerIds.length && currentUser.partnerIds[0])
				dispatch(actions.loadWidgetsForUser(JSON.parse(localStorage.getItem('m_last_app_config_' + partnerId))))
				//Open tab from url search param.
				openURLTab()
				setInterval(() => {
					const settings = JSON.stringify(store.getState().App.workspace)
					console.log('saving settings', settings)
					localStorage.setItem('m_last_app_config_' + partnerId, settings)
				}, 30000)
			}
		}
	}

	openTab(name: string, typeId: WIDGET_ID, options?: any, id?: string, newTab?: boolean) {
		const dispatch = store.dispatch
		dispatch(actions.addWidget({title: name, type: typeId, id, options, newTab, isClosable: true}))
	}

	activateTab(id: string) {
		const dispatch = store.dispatch
		dispatch(actions.activateTab(id))
	}

	setTimeZone(timezone: string) {
		BakktComponentApplication.timezone = timezone
	}

	getTimeZone() {
		return BakktComponentApplication.timezone
	}
}

export default new Application()
