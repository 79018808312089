import React from 'react'
import {logout} from '../../common/api'
import {Icons, HBox} from '@bakkt/components'
import PermissionManager, {PortalPermission} from '../../app/PermissionManager'
import MerchantSettings from './MerchantSettings'

export default () => {
	return (
		<div style={{marginLeft: 100, marginRight: 100, marginTop: 25, marginBottom: 25}}>
			<HBox style={{alignItems: 'center'}}>
				<span style={{fontSize: 20, fontFamily: 'BrownPro', display: 'flex'}}>
					Bakkt<span style={{fontSize: 17}}>&#174;&nbsp;</span>Partner Portal
				</span>
				<div style={{display: 'flex', flexGrow: 1}}></div>
				<Icons.BakktLogo width={148} height={57} />
				<div style={{display: 'flex', flexGrow: 1}}></div>
				{/* TODO check permissions */}
				{PermissionManager.userAllowed([PortalPermission.USERS_VIEW]) && <MerchantSettings />}
				<span style={{fontSize: 16, cursor: 'pointer', fontFamily: 'BrownPro'}} onClick={logout}>
					Sign Out
				</span>
			</HBox>
		</div>
	)
}
