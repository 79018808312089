import application from '../../app/Application'
import {WIDGET_ID} from '../../manifest'
import {TRANSACTION_DETAILS} from './TransactionsConstants'
import {formatAmount0, formatWithDollarSign} from '../../common/numberUtil'
import {formatDateTime, formatDateTimeWithGlobalTimezone} from '../../common/dateUtil'
import {
	ApiMerchantPortalTransactionDetailDisputeStatusEnum,
	ApiMerchantPortalTransactionDetail,
	ApiMerchantPortalTransactionSummary,
	ApiMerchantPortalTransactionDetailTransactionStatusEnum,
	ApiFunding,
} from '@bakkt/api'
import {BLANK_VALUE} from '../../common/helper'
import {DISPUTE_DETAILS} from '../disputes/DisputeConstants'

export const openTransactionDetails = id => {
	if (id) {
		application.openTab(
			`TX-${id.slice(-4)}`,
			WIDGET_ID.MERCHANT_TXN_DETAIL,
			{
				viewInTab: true,
				id,
			},
			`TX-${id}`,
		)
	}
}

const formatDisputeStatus = (status: ApiMerchantPortalTransactionDetailDisputeStatusEnum) => {
	switch (status) {
		case ApiMerchantPortalTransactionDetailDisputeStatusEnum.NEW:
		case ApiMerchantPortalTransactionDetailDisputeStatusEnum.ESCALATED:
		case ApiMerchantPortalTransactionDetailDisputeStatusEnum.MERCHANT_REJECTED:
			return status + ' (OPEN)'
		case ApiMerchantPortalTransactionDetailDisputeStatusEnum.REJECTED:
		case ApiMerchantPortalTransactionDetailDisputeStatusEnum.CANCELLED:
		case ApiMerchantPortalTransactionDetailDisputeStatusEnum.ACCEPTED:
			return status + ' (CLOSED)'
		default:
			return status || BLANK_VALUE
	}
}

const formatName = (first: string, last: string) => {
	return first + ' ' + last
}

export const formatTransactionDetails = (data: ApiMerchantPortalTransactionDetail) => {
	// TODO add second parameter for asset activity data
	return {
		column1: {
			[TRANSACTION_DETAILS.bakktTransactionId]: {value: data.bakktTransactionId},
			[TRANSACTION_DETAILS.merchantTransactionId]: {value: data.merchantTransactionRef},
			[TRANSACTION_DETAILS.merchantConsumerId]: {value: data.merchantCustomerRef},
			[TRANSACTION_DETAILS.consumerName]: {value: formatName(data.consumerFirstName, data.consumerLastName)},
			[TRANSACTION_DETAILS.consumerEmail]: {value: data.consumerEmail},
		},
		column2: {
			[TRANSACTION_DETAILS.type]: {value: data.transactionType},
			[TRANSACTION_DETAILS.txnDate]: {value: formatDateTimeWithGlobalTimezone(data.transactionDate)},
			[TRANSACTION_DETAILS.amount]: {value: formatWithDollarSign(data.amount)},
			[TRANSACTION_DETAILS.fee]: {value: formatWithDollarSign(data.fees) || '--'},
		},
		column3: {
			[TRANSACTION_DETAILS.status]: {value: formatTxnStatus(data.transactionStatus)},
			[TRANSACTION_DETAILS.disputeStatus]: {value: formatDisputeStatus(data.disputeStatus)},
			[TRANSACTION_DETAILS.fundingPreference]: {
				value: data.fundingPreference ? data.fundingPreference : data.preferredCurrency || BLANK_VALUE,
			},
			...getFundingSourcesFields(data.fundingDetails),
		},
	}
}

const getFundingSourcesFields = (fundingSourceDetails: ApiFunding[]) => {
	if (!fundingSourceDetails || fundingSourceDetails.length == 0) {
		return {}
	}
	const fundingFields: any = {}
	//asuming here cost basis is USD.
	fundingSourceDetails.forEach((funding, i) => {
		if (!funding.value || !funding.unitPrice) {
			fundingFields[`Funding ${i + 1}`] = {value: formatAmount0(funding.amount)}
		} else {
			fundingFields[`Funding ${i + 1}`] = {
				value: `${formatAmount0(funding.amount)} @ ${
					(funding.unitPrice &&
						funding.unitPrice.amount &&
						funding.unitPrice.currency &&
						formatAmount0(funding.unitPrice)) ||
					'--'
				} | ${
					(funding.value && funding.value.amount && funding.value.currency && formatAmount0(funding.value)) ||
					'--'
				}`,
			}
		}
	})

	return fundingFields
}

const formatTxnStatus = (txnStatus: ApiMerchantPortalTransactionDetailTransactionStatusEnum) => {
	if (txnStatus === ApiMerchantPortalTransactionDetailTransactionStatusEnum.REVERSED) {
		return 'REFUNDED'
	}
	return txnStatus
}

export const formatChildTxnDetails = (childTxn: ApiMerchantPortalTransactionSummary) => {
	return {
		column1: {
			[TRANSACTION_DETAILS.id]: {value: childTxn.id},
			[TRANSACTION_DETAILS.fee]: {value: formatWithDollarSign(childTxn.fees)},
		},
		column2: {
			[TRANSACTION_DETAILS.txnDate]: {value: formatDateTimeWithGlobalTimezone(childTxn.transactionDate)},
			[TRANSACTION_DETAILS.amount]: {value: formatWithDollarSign(childTxn.amount)},
		},
		column3: {
			[TRANSACTION_DETAILS.status]: {value: childTxn.transactionStatus},
		},
	}
}

export const formatDisputeDetails = (data: ApiMerchantPortalTransactionDetail) => {
	return {
		column1: {
			[DISPUTE_DETAILS.caseId]: {value: data.ticketRef},
			[DISPUTE_DETAILS.disputeId]: {value: data.disputeId},
		},
		column2: {
			[DISPUTE_DETAILS.disputeType]: {value: data.disputeType || BLANK_VALUE},
			[DISPUTE_DETAILS.disputeDate]: {value: formatDateTimeWithGlobalTimezone(data.merchantDisputeDate)},
		},
		column3: {
			[DISPUTE_DETAILS.reasonCode]: {value: data.disputeReasonCode},
			[DISPUTE_DETAILS.replyBy]: {value: formatDateTime(data.replyByDate)},
		},
	}
}
