import * as api from '@bakkt/api'
// Use openapi axios instance
import application from '../app/Application'
import axios from 'axios'
import {openTransactionDetails} from '../merchant/transactions/TransactionsHelper'
import {URLParam} from '../app/AppState'
import {validUUID} from './helper'
import Alert from './components/Alert'
import {AttachmentDetails} from '@bakkt/api'

const SERVER_URL = window['SERVER_URL'] === '{{SERVER_URL}}' ? process.env.REACT_APP_SERVER_URL : window['SERVER_URL']

export const redirectToLogin = () => {
	axios.get(`${SERVER_URL}/web/login-redirect?client=merchant`).then(response => {
		if (response.headers['content-type'] === 'text/html;charset=UTF-8')
			window.location = response.request.responseURL
	})
}

const globalErrorHandler = (error: any) => {
	application.respenseReceived()
	if (error.response && error.response.status === 401) {
		redirectToLogin()
	}
	return Promise.reject({...error})
}

export const logout = () => {
	axios.post(`${SERVER_URL}/web/logout?client=merchant`).then(response => {
		localStorage.removeItem('portal-type')
		const url = new URL(window.location.href)
		if (url.searchParams.has('selectedPartnerId')) {
			url.searchParams.delete('selectedPartnerId')
			window.history.pushState(null, document.title, url)
		}
		localStorage.removeItem('selectedPartnerId')
		window.location.reload()
	})
}

/*
 * Takes an array of fusionAuthRefs to destroy the sessions for on a permissions change
 */
export const destroyUserSessions = async userRefArray => {
	return axios.post(`${SERVER_URL}/web/destroy-sessions?client=merchant`, {
		fusionAuthRefs: userRefArray,
	})
}

export const destroyPartnerUserSessions = async userRefArray => {
	return axios.post(`${SERVER_URL}/web/destroy-sessions?client=partner`, {
		fusionAuthRefs: userRefArray,
	})
}

export const getPortalType = async () => {
	if (!localStorage.getItem('portal-type')) {
		const response = await getPortalTypeApiReq()
		if (response.data && response.data.type) {
			localStorage.setItem('portal-type', response.data.type)
			const selectedPartnerId = localStorage.getItem('selectedPartnerId')
			if (!selectedPartnerId && response.data.partnerIds && response.data.partnerIds.length > 0) {
				localStorage.setItem('selectedPartnerId', response.data.partnerIds?.[0])
			}
		}
	}
}

export const getPortalTypeApiReq = async () => {
	return axios.get(`${SERVER_URL}/partner-type`).catch(err => err.response)
}

axios.interceptors.request.use(req => {
	if (localStorage.getItem('portal-type') === 'partner') {
		const partnerId = localStorage.getItem('selectedPartnerId')
		if (partnerId) {
			req.headers['selectedPartnerId'] = partnerId
		}
	}
	application.requestSent()
	return req
})

const globalSuccessHandler = (response: any) => {
	application.respenseReceived()

	return response
}
const baseUrl = SERVER_URL

axios.interceptors.response.use(globalSuccessHandler, globalErrorHandler)
// send session cookie
axios.defaults.withCredentials = true

const configuration: api.Configuration = {
	baseOptions: {},
	isJsonMime: mime => true,
}

export const attachmentsDataList = async (file: File[]) => {
	let promises = []
	file.map(key => {
		const formData = new FormData()
		formData.append('fileToUpload', key)
		promises.push(
			axios
				.post(`${baseUrl}/merchant-portal/disputes/upload-attachment`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.catch(reason => {
					console.log('reason    ', reason)
					Alert.error(
						reason?.response?.data?.error?.message || 'Something went wrong while uploading File(s)',
					)
				}),
		)
	})
	const promisesResp = await Promise.all(promises)
	return promisesResp.map(res => (res && res.data && res.data.payload) || null).filter(el => el != null)
}
export const partnerAttachmentsDataList = async (file: File[]) => {
	let promises = []
	file.map(key => {
		const formData = new FormData()
		formData.append('fileToUpload', key)
		promises.push(
			axios
				.post(`${baseUrl}/partner-portal/ticket/upload-attachment`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.catch(reason => {
					console.log('reason    ', reason)
					Alert.error(
						reason?.response?.data?.error?.message || 'Something went wrong while uploading File(s)',
					)
				}),
		)
	})
	const promisesResp = await Promise.all(promises)
	return promisesResp.map(res => (res && res.data && res.data.payload) || null).filter(el => el != null)
}

export const retrieveAttachmentsDataList = async (attachment: AttachmentDetails[]) => {
	let promises = []
	promises.push(
		axios
			.post(`${baseUrl}/partner-portal/ticket/retrieve-attachment`, attachment, {
				responseType: 'arraybuffer',
			})
			.catch(reason => {
				console.log('reason    ', reason)
				Alert.error(reason?.response?.data?.error?.message || 'Something went wrong while downloading File(s)')
			}),
	)
	const promisesResp = await Promise.all(promises)
	return promisesResp.filter(el => el != null)
}

const openTransactionDetailsTab = transactionId => {
	if (transactionId && !validUUID(transactionId)) {
		Alert.error('Invalid Id !', transactionId)
	} else {
		openTransactionDetails(transactionId)
	}
	localStorage.removeItem('url-parameter')
}

export const openURLTab = async () => {
	//Get Url info from local storage and open transaction tab.
	const searchQuery: URLParam = JSON.parse(localStorage.getItem('url-parameter'))

	const hasTransactionId = searchQuery?.paramName == 'transaction-id'
	hasTransactionId && openTransactionDetailsTab(searchQuery?.paramId)
}

export const transactionApi = api.MerchantPortalTransactionControllerApiFactory(configuration, baseUrl)

export const disputeApi = api.MerchantPortalDisputeControllerApiFactory(configuration, baseUrl)

export const partnerDisputeApi = api.DisputeControllerApiFactory(configuration, baseUrl)

export const reportApi = api.MerchantPortalReportControllerApiFactory(configuration, baseUrl)

export const usersApi = api.MerchantUserRoleManagementControllerApiFactory(configuration, baseUrl)

export const managementApi = api.MerchantManagementControllerApiFactory(configuration, baseUrl)

export const partnerTransactionApi = api.PartnerTransactionControllerApiFactory(configuration, baseUrl)

export const partnerConsumerApi = api.PartyControllerApiFactory(configuration, baseUrl)

export const partnerUserApi = api.PartnerUserRoleControllerApiFactory(configuration, baseUrl)

export const partnerApi = api.PartnerControllerApiFactory(configuration, baseUrl)

export const partnerDashboardMetricsApi = api.DashBoardMetricsControllerApiFactory(configuration, baseUrl)

export const partnerControllerApi = api.PartnerControllerApiFactory(configuration, baseUrl)

export const partnerSlaControllerApi = api.SlaReportControllerApiFactory(configuration, baseUrl)

export const partnerSettlementFileControllerApi = api.PartnerSettlementFileControllerApiFactory(configuration, baseUrl)
